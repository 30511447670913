<template>
  <div>
    <div class="select-box">
      <img
        src="../../assets/images/Examine_img/comp.png"
        style="width: 15px"
        alt=""
      />
      <el-select
        placeholder="请选择公司"
        class="select"
        v-model="company"
        @change="changeCompy"
      >
        <el-option
          v-for="item in companyList"
          :key="item.value"
          :value="item.id"
          :label="item.companyName"
        >
          {{ item.companyName }}
        </el-option>
      </el-select>
    </div>
    <div class="box_" v-if="showTypeBtn">
      <div
        v-for="(item, index) in ['安全考核']"
        :key="index"
        class="_item"
        :class="{
          action: typeBtnIndex == index,
        }"
        @click="changeTypeBtn(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="mid_box">
      <el-button type="primary" class="edit" @click="toChoose"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <img
        style="cursor: pointer; margin: 0 10px"
        @click="delItem"
        src="../../assets/images/Examine_img/del.png"
        alt=""
      />
      <!-- <div style="margin: 15px">
        <span>年份：</span>
        <el-date-picker v-model="year" type="year" placeholder="选择年">
        </el-date-picker>
      </div> -->

      <el-checkbox
        label="已完成"
        v-model="status"
        true-label="2"
        @change="getData"
      ></el-checkbox>
      <el-checkbox
        label="未完成"
        v-model="status"
        true-label="4"
        @change="getData"
      ></el-checkbox>
      <el-checkbox
        label="有问题点"
        v-model="status"
        true-label="5"
        @change="bindCheckBox"
      ></el-checkbox>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        id="table"
        :data="tableData.slice(start, end)"
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        highlight-current-row
        @row-dblclick="toTowPage"
        @selection-change="handleSelectionChange"
        @select="selectChange"
        @select-all="selectAll"
        @row-click="handleRow"
        :cell-style="cellStyle"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <!-- <el-table-column label="岗位" prop="postName" width="120">
        </el-table-column> -->
        <el-table-column prop="companyName" label="所属公司" width="230">
        </el-table-column>

        <el-table-column
          label="抽取问题数"
          prop="titleCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="failCount"
          label="问题点数"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-show="scope.row.status == 0">未抽题</span>
            <span v-show="scope.row.status == 1">已抽题</span>
            <span v-show="scope.row.status == 2">完成</span>
            <span v-show="scope.row.status == 3">整改完成</span>
            <span v-show="scope.row.status == 4">未完成</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="completeTime"
          label="完成时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.completeTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="saveUserName"
          label="创建人"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="ruleRemark" label="备注" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage1"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="45%">
      <div style="display: flex" class="text">
        <span style="margin: 10px">添加备注:</span
        ><textarea v-model="remark" style="padding: 10px"></textarea>
      </div>
      <div style="margin-top: 20px; display: flex" class="text">
        <div style="display: flex">
          <span style="padding: 10px">抽取题目:</span>
        </div>
        <el-table
          :header-cell-style="{
            background: '#EDF0F7',
            color: '#000000',
          }"
          :data="titlelist"
          style="width: 100%"
          @selection-change="selectionDraw"
          height="300"
        >
          <el-table-column prop="module" label="文件名称"> </el-table-column>
          <el-table-column prop="questionCount" label="抽取数量">
          </el-table-column>
          <el-table-column type="selection" width="100"> </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="drawQuestions">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="题目详情" :visible.sync="dialogVisibletitle" width="30%">
      <el-form label-width="80px">
        <el-form-item label="专属">
          <el-input v-model="Stitle"></el-input>
        </el-form-item>
        <el-form-item label="通用">
          <el-input v-model="commontitle"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibletitle = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibletitle = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post } from '../../api/http'

export default {
  data() {
    return {
      elementHeight: 0,
      dialogVisible: false,
      dialogVisibletitle: false,
      option2: [],
      tableData: [],
      Company: '',
      Area: '',
      Stitle: '',
      commontitle: '',
      Company1: '',
      Area1: '',
      isshow: false,
      number: '',
      curArea: '',
      titlelist: [],
      CurId: '',
      CurName: '',
      deptmentName: '',
      deptmentName1: '',
      option3: [],
      curPost: '',
      start: 0,
      end: 10,
      answer: '',
      currentPage1: null,
      curSize: 10,
      Ctdata: '',
      dialogVisibleC: false,
      defaultProps: {
        label: 'auditTypeName',
        children: 'auditTypelist',
      },
      year: new Date().getFullYear() + '',
      showTypeBtn: true,
      typeBtnIndex: 0,
      company: '',
      companyList: [],
      examineExtract: [],
      status: '4',
      remark:'',
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != 'RulesDetail') {
      to.meta.ifDoFresh = true
    }
    next()
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false
      this.company = JSON.parse(sessionStorage.getItem('userInfo')).upCompanyId
      this.companyList = this.$store.state.companyUserList
      this.getData()
      this.getElementHeight()
    }
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return ''
      }
      return e.split('T')[0]
    },
  },
  methods: {
    changeCompy() {
      this.showTypeBtn = true
      this.getData()
    },
    getData() {
      if (this.dialogVisible) this.status = '4'
      get(
        '/api/NewExamineRules/GetByFileAudit?CompId=' +
          this.company +
          '&Name=' +
          (this.typeBtnIndex == 0 ? '安全考核' : '客服考核') +
          '&Status=' +
          this.status
      ).then((res) => {
        this.tableData = res.data
        if (this.dialogVisible) {
          this.dialogVisible = false
          this.toTowPage(res.data[0])
        }
      })
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52)
      })
    },
    selectionDraw(e) {
      this.examineExtract = e
    },
    drawQuestions() {
      var company = this.companyList.filter((i) => i.id == this.company)
      var fileaudit = {
        id: 0,
        companyId: this.company + '',
        companyName: company[0].companyName,
        deptId: 0,
        deptName: '',
        postId: '',
        postName: '',
        remark: this.remark,
        chargeUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
        planId: '',
        status: this.status * 1,
        saveTime: new Date().toISOString(),
        completeTime: new Date().toISOString(),
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
        saveUserNo: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
        saveUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
      }
      var lstExamineExtract = []
      this.examineExtract.map((i) => {
        lstExamineExtract.push({ module: i.module })
      })
      var n = this.typeBtnIndex == 0 ? '安全考核' : '客服考核'
      post(
        '/api/NewExamineRules/InsertTbFileaudit?Name=' +
          n +
          '&Remark=' +
          this.remark,
        {
          fileaudit,
          lstExamineExtract,
        }
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '抽题成功',
            })
            this.getData()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('抽题失败')
        })
    },
    cellStyle() {
      return 'cursor:pointer;'
    },
    toTowPage(e) {
      sessionStorage.setItem('Fitem', JSON.stringify(e))
      this.$router.push(
        './RulesDetail?Id=' +
          e.id +
          '&CpyID=' +
          e.companyId +
          '&typeBtnIndex=' +
          (this.typeBtnIndex == 1 ? '客服考核' : '安全考核') +
          '&status=' +
          e.status
      )
    },
    delItem() {
      this.$confirm(
        '此操作将永久删除该' + this.CurName + '考核细则',
        '是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          deleteMethod('/api/ExamineRules?Id=' + this.CurId).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    toChoose() {
      this.dialogVisible = true
      get(
        '/api/NewExamineRules/GetTitleList?Name=' +
          (this.typeBtnIndex == 0 ? '安全考核' : '客服考核')
      ).then((resp) => {
        if (resp.code == 200) {
          this.titlelist = resp.data
        }
      })
    },

    handleRow(e) {
      this.CurId = e.id
      this.CurName = '关于' + e.companyName
      this.$refs.multipleTable.toggleRowSelection(e)
      this.selectChange(this.handleSelectionList)
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value
      this.CurId = this.handleSelectionList[0].id
    },
    selectChange(selection) {
      if (selection.length > 1) {
        const del_row = selection.shift()
        this.$refs.multipleTable.toggleRowSelection(del_row, false)
      }
    },
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
      this.selectChange(this.handleSelectionList)
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1
      }
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    changeTypeBtn(i) {
      this.typeBtnIndex = i
      this.getData()
    },
    bindCheckBox() {
      // 有问题点不需调用接口
      if (this.status == 5) {
        this.tableData = []
        get(
        '/api/NewExamineRules/GetByFileAudit?CompId=' +
          this.company +
          '&Name=' +
          (this.typeBtnIndex == 0 ? '安全考核' : '客服考核') 
        ).then((resp) => {
          if (resp.code == 200) {
            resp.data.map((item) => {
              if (item.failCount > 0) this.tableData.push(item)
            })
          }
        })
        return
      }
      // if (this.status == undefined) {
      //   this.status = 0
      // }
      // this.getData()
    },
  },
}
</script>
<style scoped lang="less">
.el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
}
.select-box {
  display: flex;
  align-items: center;
  margin: 5px 0;
  img {
    margin-right: 10px;
  }
}
.box_ {
  display: flex;
  margin-top: 5px;
  ._item {
    width: 105px;
    height: 40px;
    color: #000000;
    background: #ffffff;
    border: 1px solid #e7e9ec;
    box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }
}

.mid_box {
  background-color: white;
  margin-bottom: 0 !important;
  padding: 15px;
}
.table {
  padding-top: 10px;
  border-top: 1px solid rgb(228, 228, 228);
  background-color: white;
}
::v-deep .select .el-input__inner {
  background-color: transparent !important;
  //   border-color: rgba(255, 255, 255, 0.5);
  color: #000000;
  border: 0px;
  height: 49px;
  width: 240px;
  font-size: 20px;
  text-size-adjust: 20px;
  .el-input__inner::placeholder {
    color: rgb(0, 0, 0);
    text-align: center;
  }
}
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
</style>
<style scoped>
@import '../../assets/style/Examine_CSS.css';
</style>
